import React from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import startCase from "lodash/startCase"
import capitalize from "lodash/capitalize"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import seriesDescriptions from "../content/series-descriptions.json"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import { seriesCard, seriesCardBody, seriesCardButton, seriesCardPostCount } from "../styles/seriesList.module.css"


const SeriesPage = ({ data }) => {
  const { group } = data.allMarkdownRemark

  return (
    <Layout>
      <Container fluid className="text-white my-5">
        <Row className="justify-content-center">
          <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="text-center lead">
            <h1>Top Series</h1>
            <Row className="justify-content-center">
              {group
                .sort((a, b) => (b.totalCount > a.totalCount) ? 1 : (a.totalCount === b.totalCount) ? ((a.fieldValue > b.fieldValue) ? 1 : -1) : -1)
                .map(series => {
                  const seriesDescriptionIndex = seriesDescriptions.content.findIndex(
                    seriesGroup => startCase(seriesGroup.series) === startCase(series.fieldValue)
                  )
                  return (
                    <Col key={series.fieldValue} xs={12} sm={6} xl={4}>
                      <Card className={[seriesCard]}>
                        {/* <Card.Img variant="top" src="icon.png" height={100} /> */}
                        <Card.Body className={[seriesCardBody]}>
                          <Card.Title>
                            <Link to={`/series/${kebabCase(series.fieldValue)}`} className={[seriesCardBody]}>
                              {series.fieldValue.replace(/\w+/g, capitalize)}
                            </Link>
                          </Card.Title>
                          <Card.Text className="mb-2">
                            {seriesDescriptions.content[seriesDescriptionIndex].description}
                          </Card.Text>
                          <Card.Text className={[seriesCardPostCount]}>
                            {series.totalCount} {series.totalCount === 1 ? "post" : "posts"} published
                          </Card.Text>
                          <Link to={`/series/${kebabCase(series.fieldValue)}`}>
                            <Button variant="outline-primary" className={[seriesCardButton]}>
                              Find {startCase(series.fieldValue)} {series.totalCount === 1 ? "Post" : "Posts"}
                            </Button>
                          </Link>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = (props) => {
  const pageSeo = {
    title: "My Digital Garden Blog Series",
    description: "Get motivated to make a change in your life and become a better software engineer with some practical programming advice through quick blog posts.",
    pathname: props.location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo} />
  )
}

SeriesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default SeriesPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___series) {
        fieldValue
        totalCount
      }
    }
  }
`